<template>
  <!-- <van-pull-refresh :head-height="50" v-model="refreshing" @refresh="onRefresh"> -->
  <div id="agency">
    <van-sticky :offset-top="0">
      <div class="header">
        <van-row gutter="10">
          <van-col span="10" class="header-title" @click="onClickLeft">
            <svg-icon
              :data_iconName="'back-default'"
              :className="'back_icon'"
            />旅行社详情
          </van-col>
          <van-col
            span="10"
            class="header-title"
            style="text-align: right"
            v-if="company.status == '0'"
            @click="onStatusClick(1)"
          >
            审核通过
          </van-col>
          <van-col
            span="4"
            class="header-title"
            style="text-align: right; color: #de350b"
            v-if="company.status == '0'"
            @click="onStatusClick(2)"
          >
            拒绝
          </van-col>
          <van-col
            span="14"
            class="header-title"
            style="text-align: right"
            v-if="company.status == '1'"
            @click="onDeleteClick"
          >
            删除
          </van-col>
        </van-row>
      </div>
    </van-sticky>
    <van-dialog
      v-model="showReject"
      title="审核拒绝"
      show-cancel-button
      :beforeClose="beforeClose"
    >
      <van-field
        v-model="reject_reason"
        label="拒绝原因"
        placeholder="请输入拒绝原因"
      />
    </van-dialog>
    <div class="agency-wrapper">
      <div class="agency-container">
        <van-image
          class="agency-avatar"
          round
          :src="company.avatar ? company.avatar : ''"
          ><template v-slot:error
            ><span style="font-size: 10px">暂无头像</span></template
          ></van-image
        >

        <van-row>
          <van-col span="24"
            ><div class="star-text">
              <van-rate
                v-if="company.rate_star"
                v-model="company.star"
                :size="25"
                color="#FFAA00"
                void-icon="star"
                void-color="#eee"
                readonly
                allow-half
              />
              <van-rate
                v-else
                :size="10"
                color="#f5f5f5"
                void-icon="star"
                gutter="2px"
                void-color="#fff"
                readonly
                allow-half
                value="5"
              /></div
          ></van-col>
          <van-col span="24"
            ><div class="main-text">{{ company.name }}</div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              地区：{{
                (company.province ? company.province : "") +
                "/" +
                (company.city ? company.city : "") +
                "/" +
                (company.district ? company.district : "")
              }}
            </div>
          </van-col>
          <van-col span="24"
            ><div class="agency-text">
              地址：{{ company.address ? company.address : "未完善" }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              法人代表：{{
                company.legal_person ? company.legal_person : "未完善"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              法人电话：{{ company.telephone ? company.telephone : "未完善" }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              联系人：{{
                company.contact_person ? company.contact_person : "未完善"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              联系电话：{{
                company.contact_mobile ? company.contact_mobile : "未完善"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              统一信用代码:{{
                company.social_credit_card
                  ? company.social_credit_card
                  : "未完善"
              }}
            </div></van-col
          >
          <!-- <van-col span="24"
              ><div class="agency-text">
                营业执照:
              </div></van-col
            > -->
          <van-col span="24"
            ><div class="agency-text">
              发证单位:{{ company.unit ? company.unit : "未完善" }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              许可证:
              <!-- <span
                  style="color: #426cef; text-decoration: underline"
                  > -->
              {{ company.license_no ? company.license_no : "未完善" }}
              <!-- </span -->
              <!-- > -->
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              许可文号:{{
                company.license_file_no ? company.license_file_no : "未完善"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              经营场所：{{
                company.place_of_business ? company.place_of_business : "未完善"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              传真：{{ company.fax ? company.fax : "未完善" }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              质保金：{{
                company.deposit > 0 ? company.deposit + "万元" : "未完善"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              质保银行：{{
                company.deposit_bank ? company.deposit_bank : "未完善"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              <!-- <div style="height:80px;line-height:80px;display:inline-block"></div> -->
              质保凭条：<van-uploader
                v-if="fileList.length > 0"
                :deletable="false"
                readonly
                v-model="fileList"
                :max-count="1"
              />
              <span v-else>未完善</span>
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              成立日期：{{
                company.establish_date ? company.establish_date : "未完善"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              营业截止日期：{{
                company.valid_period ? company.valid_period : "未完善"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              营业执照：<van-uploader
                v-if="fileList1.length > 0"
                :deletable="false"
                readonly
                v-model="fileList1"
                :max-count="1"
              />
              <span v-else>未完善</span>
            </div></van-col
          >
        </van-row>
        <div class="qr-container">
          <van-row>
            <van-col span="24"
              ><div class="agency-text">
                <div class="qr-img">
                  <vue-qr
                    :text="downloadData.url"
                    :margin="0"
                    colorDark="#333"
                    colorLight="#fff"
                    :logoScale="0.3"
                    :size="200"
                  ></vue-qr>
                </div>
              </div>
            </van-col>
            <van-col span="24">
              <a
                v-if="company.contact_mobile"
                :href="'tel:' + company.contact_mobile"
                class="agency-text"
                style="
                  color: #426cef;
                  text-decoration: underline;
                  display: block;
                "
              >
                <!-- <svg-icon
                  :data_iconName="'mobile'"
                  :className="'mobile_icon'"
                /> -->
                {{ company.contact_mobile ? company.contact_mobile : "未完善" }}
              </a>
              <div class="agency-text" v-if="!company.contact_mobile">
                暂无联系电话
              </div>
            </van-col>
            <van-col span="24"
              ><div class="sub-text state">
                {{ company.status_text }}
              </div></van-col
            >
          </van-row>
        </div>
        <van-row class="bottom">
          <van-col span="24">
            <van-cell
              title="查看评价"
              is-link
              :to="'/agency/feedbacks?id=' + $route.query.id"
            />
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24">
            <van-cell
              title="查看导游"
              is-link
              :to="'/agency/guides?id=' + $route.query.id"
            />
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24">
            <van-cell
              title="查看行程"
              is-link
              :to="'/agency/trip?id=' + $route.query.id"
            />
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24">
            <van-cell
              title="查看出团"
              is-link
              :to="'/agency/tuan?id=' + $route.query.id"
            />
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
  <!-- </van-pull-refresh> -->
</template>

<script>
import { Toast } from "vant";
import { Dialog } from "vant";
import vueQr from "vue-qr";
import { QrURL } from "@/services/config";
export default {
  name: "agency-item",
  data() {
    const _self = this;
    return {
      refreshing: false,
      showReject: false,
      reject_reason: "",
      loading: true,
      company: {},
      downloadData: {
        url: `${QrURL}/guider/companyInfo/${_self.$route.query.id}`, //测试
        //  url: `https://visitor.wenlvanquan.com/guider/companyInfo/${_self.$route.query.id}`,//正式
        icon: "",
      },
      fileList: [],
      fileList1: [],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    async get_travel_companie(id) {
      const res = await this.$apis.get_travel_companie(id);
      this.company = res.data;
      if (res.data.deposit_receipt) {
        this.fileList = [{ url: res.data.deposit_receipt }];
      }
      if (res.data.license_img) {
        this.fileList1 = [{ url: res.data.license_img }];
      }

      this.loading = false;
      this.refreshing = false;
    },
    async examine(status) {
      const _self = this;
      const id = this.$route.query.id;
      const data = {
        travel_company: {
          status: status,
          reject_reason: _self.reject_reason,
        },
      };
      const res = await this.$apis.put_companies_status(data, id);
      if (res.status == 200) {
        Toast("操作成功");
        this.get_travel_companie(id);
        this.showReject = false;
        this.reject_reason = "";
      }
    },
    beforeClose(action, done) {
      if (action == "confirm") {
        if (!this.reject_reason) {
          this.$toast("请输入拒绝原因");
          done(false);
          return false;
        }
        this.examine(2);
      }
      if (action == "cancel") {
        this.showReject = false;
      }
    },
    onStatusClick(status) {
      if (status == 2) {
        this.showReject = true;
        return false;
      }
      const _self = this;
      Dialog.confirm({
        title: "温馨提示",
        message: status == 1 ? "确认通过审核吗" : "确认拒绝吗",
      })
        .then(() => {
          _self.examine(status);
        })
        .catch(() => {});
    },
    onDeleteClick() {
      const _self = this;
      Dialog.confirm({
        // title: "标题",
        message: "确定删除该旅行社吗?",
      })
        .then(() => {
          _self.delete_travel_company();
        })
        .catch(() => {});
    },
    async delete_travel_company() {
      const id = this.$route.query.id;
      const res = await this.$apis.delete_travel_company(id);
      if (res.status == 200) {
        Toast("删除成功");
        this.$router.go(-1);
      }
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      const id = this.$route.query.id;
      if (!id) {
        Toast("参数错误");
        this.$router.go(-1);

        return false;
      }
      this.get_travel_companie(id);
    },
  },
  components: {
    vueQr,
  },
  created() {
    this.onRefresh();
  },
};
</script>

<style scoped>
#agency {
  height: 100vh;
  /* overflow-y: scroll; */
  /* padding-top:10vh; */
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.agency-wrapper {
  padding: 0.4rem;
  position: relative;
}
.agency-container {
  padding: 0.4rem;
  background-color: #fff;
  border-radius: 20px;
  height: 82vh;
  overflow-y: scroll;
  padding-bottom: 4rem;
}
.agency-avatar {
  position: absolute;
  top: -0.35rem;
  left: 50%;
  transform: translateX(-50%);
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  z-index: 100;
}
.agency-avatar img {
  width: 100%;
  height: 100%;
}

.agency-text {
  font-size: 14px;
  color: #666666;
  margin-top: 0.2rem;
  text-align: center;
}
.main-text {
  font-size: 16px;
  color: #333333;
  text-align: center;
  font-weight: bold;
}
.star-text {
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
}
.agency-a {
  color: #1989fa;
}
.sub-text {
  font-size: 14px;
  color: #666666;
  margin-top: 5px;
  text-align: center;
}
.qr-container {
  margin-top: 20px;
}
.qr-img {
  background: #ebebeb;
  border-radius: 28px;
  border-radius: 28px;
  width: 200px;
  height: 200px;
  line-height: 160px;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 20px;
}
.state {
  background: rgba(64, 169, 255, 0.16);
  border-radius: 17px;
  border-radius: 17px;
  height: 34px;
  width: 80px;
  line-height: 34px;
  padding: 0 10px;
  margin: 0 auto;
  margin-top: 10px;
  color: #40a9ff;
}
.mobile_icon {
  font-size: 0.5rem;
}
.van-image__img,
.van-uploader {
  vertical-align: middle !important;
  z-index: 1;
}
</style>
